<template>
    <div>
        <div v-if="!modal_loaded">
            <LoadingSpinner class="col-12" />
        </div>

        <form v-else @submit.prevent="checkForm" class="mb-4">
            <input type="hidden" id="accountingplan_id" class="form-control" v-model="form.accountingplan_id">
            <div class="row">
                <div class="col-12" v-if="form_message !== ''">
                    <ErrorAlert :messageI18n="form_message" />
                </div>
				
				<div class="col-12">
					<div class="form-group">
						<b-form-checkbox
							id="default"
							v-model="form.accountingplan_default"
							unchecked-value="false"
							:disabled="readonly"
						>
						{{ $t('compta.form.default') }}
						</b-form-checkbox>
					</div>
				</div>

                <div class="col-12">
                    <div class="form-group">
                        <label for="accountingplan_tiers" class="col-form-label">{{ $t("tiers.tier") }}<span v-if="!readonly"> *</span></label>
                        <e-select
                            v-model="form.accountingplan_tiers"
                            id="accountingplan_tiers"
                            track-by="name"
                            label="name"
                            :placeholder="labelTitleTiers"
                            :deselectLabel="deselectLabel"
                            :selectedLabel="selectedLabel"
                            :selectLabel="enter_to_select"
                            :options="tiers"
                            :searchable="true"
                            :show-labels="false"
                            :allow-empty="false"
                            :disabled="readonly_tiers"
                        >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label for="accountingplan_label" class="col-form-label">{{ $t("compta.form.nom_comptabilite") }}<span v-if="!readonly"> *</span></label>
                        <input
                            type="text"
                            id="accountingplan_label"
                            class="form-control"
                            v-model="form.accountingplan_label"
                            required
                            :readonly="readonly"
                        >
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group">
                        <label for="accountingplan_sales_journal" class="col-form-label">{{ $t("compta.form.accountingplan_sales_journal") }}<span v-if="!readonly"> *</span></label>
                        <input
                            class="form-control"
                            v-model="form.accountingplan_sales_journal"
                            :class="{ 'is-invalid': error && error.indexOf('Erreur journal ventes') > -1 }"
                            :readonly="readonly"
                        >
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group">
                        <label for="accountingplan_bank_journal" class="col-form-label">{{ $t("compta.form.accountingplan_bank_journal") }}<span v-if="!readonly"> *</span></label>
                        <input
                            class="form-control"
                            v-model="form.accountingplan_bank_journal"
                            :class="{ 'is-invalid': error && error.indexOf('Erreur journal banque') > -1 }"
                            :readonly="readonly"
                        >
                    </div>
                </div>

                <div class="col-12" v-if="hasInvoiceSupplierOCR">
                    <div class="form-group">
                        <label for="accountingplan_supplier_journal" class="col-form-label">{{ $t("compta.form.accountingplan_supplier_journal") }}<span v-if="!readonly"> *</span></label>
                        <input
                            class="form-control"
                            v-model="form.accountingplan_supplier_journal"
                            :readonly="readonly"
                        >
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label class="col-form-label">{{ $t("compta.form.numero_facture") }}</label>
                        <div class="form-inline">
                            <input
                                class="form-control col-2"
                                v-model="form.accountingplan_prefix.numbertemplate_prefix"
                                required
                                :readonly="readonly"
                            />
                            <b-input-group class="col-5">
                                <b-input-group-prepend id="popover-target-format-info">
                                    <span class="input-group-text">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                    </span>
                                </b-input-group-prepend>
                                <b-form-input
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur accountingplan_prefix') > -1 }"
                                    v-model="form.accountingplan_prefix.numbertemplate_pattern"
                                    required
                                    :readonly="readonly"
                                ></b-form-input>
                                <b-popover target="popover-target-format-info" triggers="hover" placement="top">
                                    <template v-slot:title>{{ $t('compta.form.format_numero_facture') }}</template>
                                    <p
                                        v-for="(val, key) in date_formatter"
                                        :key="key"
                                    >
                                        <b>{{ key }}</b> - {{ val.label }} - {{ val.value }}
                                    </p>

                                    <b>{{ $t('compta.form.caracteres_autorises_pattern') }}</b>
                                    
                                </b-popover>
                            </b-input-group>
                            <span disabled class="form-control col-5">
                                {{ formatted_invoice_num }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label class="col-form-label">{{ $t("compta.form.a_partir_de") }}</label>
                        <input
                            type="number"
                            class="form-control"
                            v-model="form.accountingplan_fromnumber"
                            required
                            :readonly="readonly"
                        >
                    </div>
                </div>



                <div class="col-12">
                    <div class="form-group">
						<div class="row">
							<label class="col-form-label col-3">{{ $t("compta.form.numero_compte_client") }}</label>
							<div class="col-form-label col-9" v-if="!accountingplan_default">
								<b-form-checkbox
									id="default_client_account"
									v-model="form.accountingplan_clientaccountfromdefault"
									unchecked-value="false"
									:disabled="readonly"
								>
								{{ $t('compta.form.compte_client_parent') }}
								</b-form-checkbox>
							</div>
						</div>
                        <div class="form-inline">
                            <input
                                class="form-control col-2"
                                v-model="form.accountingplan_prefix_compteclient.numbertemplate_prefix"
                                required
                                :readonly="readonly || accountingplan_clientaccountfromdefault"
                            />
                            <b-input-group class="col-5">
                                <b-input-group-prepend id="popover-target-format-client-info">
                                    <span class="input-group-text">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                    </span>
                                </b-input-group-prepend>
                                <b-form-input
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur accountingplan_prefix_compteclient') > -1 }"
                                    v-model="form.accountingplan_prefix_compteclient.numbertemplate_pattern"
                                    required
                                    :readonly="readonly || accountingplan_clientaccountfromdefault"
                                ></b-form-input>
                                <b-popover target="popover-target-format-client-info" triggers="hover" placement="top">
                                    <template v-slot:title>{{ $t('compta.form.format_numero_client') }}</template>
                                    <p
                                        v-for="(val, key) in client_account_formatter"
                                        :key="key"
                                    >
                                        <b>{{ key }}</b> - {{ val.label }} - {{ val.value }}
                                    </p>

                                    <b>{{ $t('compta.form.caracteres_autorises_pattern') }}</b>
                                    
                                </b-popover>
                            </b-input-group>
                            <span disabled class="form-control col-5">
                                {{ formatted_client_account }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12" v-if="hasInvoiceSupplierOCR && form.accountingplan_prefix_compteclient_supplier">
                    <b-alert show variant="warning" class="mt-2" v-if="no_supplier_prefix">
                        <font-awesome-icon :icon="['fal', 'exclamation-triangle']" style="color: #8F5922;"/>
                        {{ $t('compta.no_supplier_prefix_saved') }}
                    </b-alert>
                    <div class="form-group">
						<div class="row">
							<label class="col-form-label col-3">{{ $t("compta.form.numero_compte_client_supplier") }}</label>
							<div class="col-form-label col-9" v-if="!accountingplan_default">
								<b-form-checkbox
									id="default_client_account"
									v-model="form.accountingplan_clientaccountfromdefault_supplier"
									unchecked-value="false"
									:disabled="readonly"
								>
								{{ $t('compta.form.compte_client_parent') }}
								</b-form-checkbox>
							</div>
						</div>
                        <div class="form-inline">
                            <input
                                class="form-control col-2"
                                v-model="form.accountingplan_prefix_compteclient_supplier.numbertemplate_prefix"
                                required
                                :readonly="readonly || accountingplan_clientaccountfromdefault_supplier"
                            />
                            <b-input-group class="col-5">
                                <b-input-group-prepend id="popover-target-format-client-info">
                                    <span class="input-group-text">
                                        <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                    </span>
                                </b-input-group-prepend>
                                <b-form-input
                                    :class="{ 'is-invalid': error && error.indexOf('Erreur accountingplan_prefix_compteclient') > -1 }"
                                    v-model="form.accountingplan_prefix_compteclient_supplier.numbertemplate_pattern"
                                    required
                                    :readonly="readonly || accountingplan_clientaccountfromdefault_supplier"
                                ></b-form-input>
                                <b-popover target="popover-target-format-client-info" triggers="hover" placement="top">
                                    <template v-slot:title>{{ $t('compta.form.format_numero_client') }}</template>
                                    <p
                                        v-for="(val, key) in client_account_formatter"
                                        :key="key"
                                    >
                                        <b>{{ key }}</b> - {{ val.label }} - {{ val.value }}
                                    </p>

                                    <b>{{ $t('compta.form.caracteres_autorises_pattern') }}</b>
                                    
                                </b-popover>
                            </b-input-group>
                            <span disabled class="form-control col-5">
                                {{ formatted_client_account_supplier }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="form-group">
                        <label class="col-form-label">{{ $t("compta.form.a_partir_de_compte_client") }}</label>
                        <input
                            type="number"
                            class="form-control"
                            v-model="form.accountingplan_nextclientaccount"
                            required
                            :readonly="readonly"
                        >
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group">
                        <label class="col-form-label">{{ $t('compta.date_cloture') }}</label>
                        <e-datepicker v-model="form.accountingplan_closing_date" :disabled="readonly"></e-datepicker>
                        <i>{{ $t('compta.date_cloture_texte') }}</i>
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group">
                        <label class="col-form-label">{{ $t('compta.arrondir_dixieme') }}
                            <a :href="getTrad('compta.arrondir_dixieme_url')" target="_blank" rel="noopener noreferrer">
                            <font-awesome-icon :icon="['fas', 'external-link']" />
                        </a></label> 
                        <b-form-checkbox
                            id="rounded"
                            v-model="form.accountingplan_rounded"
                            unchecked-value="false"
                            :disabled="readonly"
                        />
                    </div>
                </div>

                <div class="col-6">
                    <div class="form-group">
                        <label class="col-form-label">{{ $t('global.format_date') }}</label>
                        <e-select
                            v-model="format_date"
                            :options="formats_dates"
                            :allow-empty="false"
                            label="label"
                            track-by="code"
                        />
                    </div>
                </div>
            </div>
        </form>

        <div class="col-auto m-auto text-center">
            <b-button v-if="modifier" variant="secondary" class="rounded-pill mr-3" >
                <span v-if="readonly" @click="$emit('update:readonly', false)">
                    <font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
                    {{ $t("global.modifier") }}
                </span>
                <span v-else @click="$emit('update:readonly', true)">
                    <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon> {{ $t("global.annuler") }}
                </span>
            </b-button>
            <b-button v-if="modifier && !readonly" pill variant="primary" class="px-5" @click="checkForm">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                <font-awesome-icon v-else :icon="['fal', 'save']"></font-awesome-icon> {{ $t("global.enregistrer") }}</b-button>

            <b-button v-if="ajouter" pill variant="primary" class="px-5" @click="checkForm">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
            {{ $t("global.ajouter") }}</b-button>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import Accounting from "@/mixins/Accounting.js"
    import AccountingAccount from "@/mixins/AccountingAccount.js"
    import Tiers from "@/mixins/Tiers.js"
    import Tools from "@/mixins/Tools.js"
    import Common from '@/assets/js/common.js'

    export default {
        name: "AccountingAjout",
        mixins: [Navigation, Accounting, Tools, Tiers, AccountingAccount],
        props: {
            accountingplan_id: [String, Number],
            duplicate: {
                type: Boolean,
                default: () => (false)
            },
            readonly: {
                type: Boolean,
                default: () => (false)
            },
            readonly_tiers: {
                type: Boolean,
                default: () => (false)
            }
        },
        data () {
            return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),

                labelTitleTiers: this.getTrad("compta.search_tiers"),
                tiers: [],

                modal_loaded: false,
                ajouter: false,
                modifier: false,
                processing: false,
                form_message: "",
                form: {},
                error: [],
                default_form: {
                    accountingplan_id: '',
                    accountingplan_label: '',
                    accountingplan_tiers: {},
                    accountingplan_fromnumber: 1,
                    accountingplan_nextclientaccount: 1,
                    accountingplan_closing_date: new Date(),
                    accountingplan_sales_journal: "VE",
                    accountingplan_bank_journal: "BQ",
                    accountingplan_supplier_journal: "",
                    accountingplan_prefix: {
                        numbertemplate_prefix: 'F',
                        numbertemplate_pattern: 'Y9999'
                    },
                    accountingplan_prefix_compteclient: {
                        numbertemplate_prefix: '411',
                        numbertemplate_pattern: 'SSSSSS99'
                    },
                    accountingplan_prefix_compteclient_supplier: {
                        numbertemplate_prefix: '401',
                        numbertemplate_pattern: 'SSSSSS99'
                    },
					accountingplan_default: false,
					accountingplan_clientaccountfromdefault: false,
                    accountingplan_rounded: false
                },
                accountingAccount: [
                    {
                        accountingaccount_vat : 1,
                        accountingaccount_label: "Compte de TVA 0%",
                        accountingaccount_number: "445700"
                    },
                    {
                        accountingaccount_vat : 2,
                        accountingaccount_label: "Compte de TVA 5.5%",
                        accountingaccount_number: "445705"
                    },
                    {
                        accountingaccount_vat : 3,
                        accountingaccount_label: "Compte de TVA 10%",
                        accountingaccount_number: "445710"
                    },
                    {
                        accountingaccount_vat : 4,
                        accountingaccount_label: "Compte de TVA 20%",
                        accountingaccount_number: "445720"
					},
					{
                        accountingaccount_vat : 0,
                        accountingaccount_label: "Compte de Débours",
						accountingaccount_number: "467000",
						accountingaccount_debours: 1,
					},
					{
                        accountingaccount_vat : 0,
                        accountingaccount_label: "Compte d'Avance de Frais",
                        accountingaccount_number: "708000",
						accountingaccount_avf: 1,
                    }
                ],
                date_formatter: {},
                authorizedCaracters: "[^0-9A-Za-z-]+",
                formats_dates: [],
                format_date: null,
                no_supplier_prefix: false
            }
        },
        watch: {
            accountingplan_id(val) {
                this.addOrEdit(val)
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            init_component() {
                const now = new Date()
                this.date_formatter = {
                    'y': {
                        label: this.$t('compta.form.format_y'),
                        value: now.getFullYear().toString().slice(-2)
                    },
                    'Y': {
                        label: this.$t('compta.form.format_Y'),
                        value: now.getFullYear(),
                    },
                    'm': {
                        label: this.$t('compta.form.format_m'),
                        value: (now.getMonth() + 1).toString().padStart(2, '0')
                    },
                    'd': {
                        label: this.$t('compta.form.format_d'),
                        value: now.getDate().toString().padStart(2, '0')
                    },
                    '9': {
                        label: this.$t('compta.form.format_9'),
                        value: 0
                    },
                }

                this.client_account_formatter = {
                    'P': {
                        label: this.$t('compta.form.format_P'),
                        value: 'P'
                    },
                    'N': {
                        label: this.$t('compta.form.format_N'),
                        value: 'N'
                    },
                    'S': {
                        label: this.$t('compta.form.format_S'),
                        value: 'S'
                    },
                    '9': {
                        label: this.$t('compta.form.format_9'),
                        value: 0
                    },
                }

                this.formats_dates = Common.getAllDateFormat()

                this.addOrEdit(this.accountingplan_id)
            },
            async addOrEdit(accountingplan_id) {
                this.form_message = ""
                this.modal_loaded = false
                this.no_supplier_prefix = false
                if(accountingplan_id) {
                    let form = await this.loadAccountingPlan(accountingplan_id)
                    if(form.accountingplan_closing_date != null) {
                        form.accountingplan_closing_date = new Date(form.accountingplan_closing_date)
                    }

                    this.form = form

                    if(this.form.tiers) {
                        this.form.accountingplan_tiers = {name: this.form.tiers.tiers_rs, id: this.form.tiers.tiers_id }
                    }
					if(this.form.accountingplan_default) {
						this.form.accountingplan_default = true
					}
                    if(this.form.accountingplan_rounded) {
                        this.form.accountingplan_rounded = true
                    }
					if(this.form.accountingplan_clientaccountfromdefault) {
						this.form.accountingplan_clientaccountfromdefault = true
						this.form.accountingplan_clientaccountfromdefault_supplier = true
					}
                    if (!this.form.accountingplan_prefix_compteclient_supplier){
                        this.no_supplier_prefix = true
                        this.form.accountingplan_prefix_compteclient_supplier = this.default_form.accountingplan_prefix_compteclient_supplier
                    }
                    this.format_date = this.formats_dates.find(format => format.code == this.form.accountingplan_format_date)

                    //En cas de duplication je pré-rempli pas le tiers et le nom de la compta
                    if(this.duplicate)
                    {
                        this.form.accountingplan_tiers = {}
                        this.form.accountingplan_label = ""
						this.form.accountingplan_default = false
                    }

                    this.ajouter = false
                    this.modifier = true
                } else {
                    this.form = this.deppCloneObj(this.default_form)
                    this.format_date = this.formats_dates.find(format => format.code == 'd/m/Y')
                    this.ajouter = true
                    this.modifier = false
                }

                if(Object.keys(this.tiers).length == 0) {
                    const tiers = await this.loadTiersEntity()
                    if(tiers) {
                        for(let i = 0; i < tiers.length; i++) {
                            this.tiers.push({
                                name: tiers[i].tiers_rs, 
                                id: tiers[i].tiers_id 
                            })
                        }
                    }
                }

                this.modal_loaded = true
            },
            checkForm: async function () {
                if(this.processing == false)
                {
                    this.processing = true
                    this.form_message = ""
                    this.error = []

					if(this.form.accountingplan_default == true){
						this.form.accountingplan_clientaccountfromdefault = false
					}

                    if(!this.form.accountingplan_tiers || Object.keys(this.form.accountingplan_tiers).length == 0) {
                        this.error.push("Erreur tier")
                    }

                    if(!this.form.accountingplan_label) {
                        this.error.push("Erreur nom")
                    }

                    if(!this.form.accountingplan_sales_journal) {
                        this.error.push("Erreur journal ventes")
                    }

                    if(!this.form.accountingplan_bank_journal) {
                        this.error.push("Erreur journal banque")
                    }

                    const regex = new RegExp(this.authorizedCaracters);
                    if(regex.test(this.form.accountingplan_prefix.numbertemplate_pattern))
                    {
                        this.error.push("Erreur accountingplan_prefix")
                    }

                    if(regex.test(this.form.accountingplan_prefix_compteclient.numbertemplate_pattern))
                    {
                        this.error.push("Erreur accountingplan_prefix_compteclient")
                    }

                    if(this.hasInvoiceSupplierOCR && regex.test(this.form.accountingplan_prefix_compteclient_supplier.numbertemplate_pattern))
                    {
                        this.error.push("Erreur accountingplan_prefix_compteclient")
                    }

                    let result = null
                    if(this.error.length == 0) {
                        if(this.form.accountingplan_id) {

                            if(this.duplicate)
                            {
                                result = await this.duplicateAccountingPlan(
                                    this.form.accountingplan_id,
                                    this.form.accountingplan_tiers.id,
                                    this.form.accountingplan_label,
                                    this.form.accountingplan_sales_journal,
                                    this.form.accountingplan_bank_journal,
                                    this.form.accountingplan_fromnumber,
                                    this.form.accountingplan_prefix.numbertemplate_prefix,
                                    this.form.accountingplan_prefix.numbertemplate_pattern,
                                    this.form.accountingplan_nextclientaccount,
                                    this.form.accountingplan_prefix_compteclient.numbertemplate_prefix,
                                    this.form.accountingplan_prefix_compteclient.numbertemplate_pattern,
                                    this.form.accountingplan_prefix_compteclient_supplier.numbertemplate_prefix,
                                    this.form.accountingplan_prefix_compteclient_supplier.numbertemplate_pattern,
									this.form.accountingplan_default,
									this.form.accountingplan_clientaccountfromdefault,
                                    this.form.accountingplan_closing_date,
                                    this.form.accountingplan_rounded,
                                    this.format_date.code,
                                    this.form.accountingplan_supplier_journal
                                );
                            }
                            else
                            {
                                result = await this.editAccountingPlan(
                                    this.form.accountingplan_id,
                                    this.form.accountingplan_tiers.id,
                                    this.form.accountingplan_label,
                                    this.form.accountingplan_sales_journal,
                                    this.form.accountingplan_bank_journal,
                                    this.form.accountingplan_fromnumber,
                                    this.form.accountingplan_prefix.numbertemplate_prefix,
                                    this.form.accountingplan_prefix.numbertemplate_pattern,
                                    this.form.accountingplan_nextclientaccount,
                                    this.form.accountingplan_prefix_compteclient.numbertemplate_prefix,
                                    this.form.accountingplan_prefix_compteclient.numbertemplate_pattern,
                                    this.form.accountingplan_prefix_compteclient_supplier.numbertemplate_prefix,
                                    this.form.accountingplan_prefix_compteclient_supplier.numbertemplate_pattern,
									this.form.accountingplan_default,
									this.form.accountingplan_clientaccountfromdefault,
                                    this.form.accountingplan_closing_date,
                                    this.form.accountingplan_rounded,
                                    this.format_date.code,
                                    this.form.accountingplan_supplier_journal
                                );
                            }
                            if (result){
                                this.no_supplier_prefix = false
                            }
                        } else {
                            result = await this.addAccountingPlan(
                                this.form.accountingplan_tiers.id,
                                this.form.accountingplan_label,
                                this.form.accountingplan_sales_journal,
                                this.form.accountingplan_bank_journal,
                                this.form.accountingplan_fromnumber,
                                this.form.accountingplan_prefix.numbertemplate_prefix,
                                this.form.accountingplan_prefix.numbertemplate_pattern,
                                this.form.accountingplan_nextclientaccount,
                                this.form.accountingplan_prefix_compteclient.numbertemplate_prefix,
                                this.form.accountingplan_prefix_compteclient.numbertemplate_pattern,
                                this.form.accountingplan_prefix_compteclient_supplier.numbertemplate_prefix,
                                this.form.accountingplan_prefix_compteclient_supplier.numbertemplate_pattern,
								this.form.accountingplan_default,
								this.form.accountingplan_clientaccountfromdefault,
                                this.form.accountingplan_closing_date,
                                this.form.accountingplan_rounded,
                                this.format_date.code,
                                false,
                                this.form.accountingplan_supplier_journal
                            );

                            if(result) {
                                // J'ajoute les compte comptables de tva par défaut
                                for (let i in this.accountingAccount) {
                                    await this.addAccountingAccount(
                                        result.retour.accountingplan_id,
                                        this.accountingAccount[i].accountingaccount_vat,
                                        this.accountingAccount[i].accountingaccount_label,
                                        this.accountingAccount[i].accountingaccount_number,
                                        this.accountingAccount[i].accountingaccount_debours || 0,
                                        this.accountingAccount[i].accountingaccount_avf || 0
                                    )
                                }
                            }
                        }
                        if(result) {
                            this.$emit('ok')
                            this.processing = false
                        } else {
                            this.form_message = "error.LEP"
                            this.processing = false
                        }
                    } else {
                        this.form_message = "formulaire.erreur_champs_non_remplis"
                        this.processing = false
                    }
                }
            }
        },
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') ,
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
        },
        computed: {
            formatted_invoice_num () {
                let num = this.form.accountingplan_prefix.numbertemplate_pattern

                Object.keys(this.date_formatter).forEach(identifier => {
                    num = num.replace(new RegExp(identifier, 'g'), this.date_formatter[identifier].value)
                })

                return this.form.accountingplan_prefix.numbertemplate_prefix + num
            },

            formatted_client_account () {
                let num = this.form.accountingplan_prefix_compteclient.numbertemplate_pattern

                Object.keys(this.client_account_formatter).forEach(identifier => {
                    num = num.replace(new RegExp(identifier, 'g'), this.client_account_formatter[identifier].value)
                })

                return this.form.accountingplan_prefix_compteclient.numbertemplate_prefix + num
            },

            formatted_client_account_supplier () {
                let num = this.form.accountingplan_prefix_compteclient_supplier.numbertemplate_pattern

                Object.keys(this.client_account_formatter).forEach(identifier => {
                    num = num.replace(new RegExp(identifier, 'g'), this.client_account_formatter[identifier].value)
                })

                return this.form.accountingplan_prefix_compteclient_supplier.numbertemplate_prefix + num
            },

			accountingplan_clientaccountfromdefault () {
				return this.form.accountingplan_clientaccountfromdefault == true ? true : false
			},

			accountingplan_clientaccountfromdefault_supplier () {
				return this.form.accountingplan_clientaccountfromdefault_supplier == true ? true : false
			},

			accountingplan_default () {
				return this.form.accountingplan_default == true ? true : false
			},
            
            hasInvoiceSupplierOCR () {
                return this.$store.state.userAccess.hasInvoiceSupplierOCR
            }
        }
    }
</script>
